import React from 'react'
import PayPalButton from '../PaypalButton';
export default function Payment({ amount, handleSubmit}) {

  return (
    <div className='form paypalButtonsContainer'>
      <PayPalButton amount={amount} handleSubmit={handleSubmit} />
    </div>
  )
}

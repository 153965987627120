import React, {useState, useEffect, useCallback} from 'react'
import TOS from './ModalSlides/TOS';
import Info from './ModalSlides/Info';
import Payment from './ModalSlides/Payment';

export default function Modal({  open, close, handleSubmit, name, setName, amount, setAmount, tos, setTOS, minimumDonation}) {
  const [curSlide, setCurSlide] = useState(0);

  const handleClose = useCallback(() => {
    setCurSlide(0);
    close();
  }, [close])
  
  const submitCallback = useCallback(() => {
    handleSubmit();
    handleClose();
  },[handleSubmit, handleClose])

  useEffect(() => {
    const closeOnEsc = e => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', closeOnEsc);
    return () => window.removeEventListener('keydown', closeOnEsc);
  }, [handleClose]);
  
  function next() {
    if (curSlide < 3) setCurSlide(curSlide + 1);
    else { setCurSlide(0); handleClose();}
 }

  return (
    <section className='modalOuter' style={{
      visibility: open ? 'visible' : 'hidden',
      opacity: open ? '1' : '0',
    }}
    onClick={handleClose} // click to close
    >
      <div className='modalInner' onClick={e => e.stopPropagation() // disable click to close on inner 
      }> 
        {Boolean(curSlide === 0) && <TOS tos={tos} setTOS={setTOS} next={next}  />}
        {Boolean(curSlide === 1) && <Info name={name} setName={setName} amount={amount} setAmount={setAmount} next={next} minimumDonation={minimumDonation} />}
        {Boolean(curSlide === 2) && <Payment amount={amount} handleSubmit={() => { submitCallback(); handleClose(); }} />}
      </div>
    </section>
  )
}
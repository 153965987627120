import React from 'react'

export default function Info({name, setName, amount, setAmount, next, minimumDonation}) {
  function validateAmount(input) {
    const regex =/^[0-9]*$/;
    return Boolean(input && String(input).match(regex) && Number.isInteger(parseInt(input)) && parseInt(input) > 0);
  }

  function validateName(input) {
    return input && input.length > 2;
  }

  return (
    <form onSubmit={next} >
      <div className='inputFields'>
        <label htmlFor="name">Name:</label>
        <input required name="name" minLength={3} maxLength={10} type="text" placeholder='Type your name here...' value={name} onChange={(e)=>{setName(e.target.value)} } />
        <br />
        <label required htmlFor="amount">Donation:</label>
        <input name="amount" pattern='^[0-9]*$' min={minimumDonation || 1} type="number" placeholder='Type your donation here...' value={amount} onChange={(e) => { setAmount(e.target.value) }} />
        {minimumDonation > 1 && <h2 style={{ color: 'blue' }}>Minimum donation: <span style={{ color: 'darkred' }}>{minimumDonation}</span></h2>}            
      </div>
        <br />
      <button type="submit" disabled={!validateName(name) || !name || !amount || !validateAmount(amount)}>Save</button>
    </form>
  )
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseconfig } from "../firebaseconfig";
import {getDatabase} from "firebase/database";
import {getFirestore} from "firebase/firestore";

// Initialize Firebase
export const app = initializeApp(firebaseconfig);
export const analytics = getAnalytics(app);
export const realtimeDB = getDatabase(app);
export const firestoreDB = getFirestore(app);
import React from 'react'

export default function Entry({ entry, number }) {
  const timeStamp = new Date(entry?.time?.seconds * 1000).toLocaleString();
  return (
    <div style={{ fontSize: number < 5 ? '2rem' : '1rem' }}>

      {number === 0 && <span>👑 </span>}
      {number === 1 && <span>🥈 </span>}
      {number === 2 && <span>🥉 </span>}
      {number === 3 && <span>🎩 </span>}
      {number === 4 && <span>💰 </span>}
      {10 > number && number > 4 && <span>💸  </span>}
      {20 > number && number > 10 && <span>✨  </span>}
      {30 > number && number > 20 && <span>💖  </span>}
      {70 > number && number > 30 && <span>☺️  </span>}
      {95 > number && number > 70 && <span>😐  </span>}
      {100 > number && number > 95 && <span>💀  </span>}

      <span style={{ fontSize: number < 5 ? '2rem' : '1.5rem' }}>{ number + 1}. </span>
      <strong >{entry.name}</strong>
      {' '}${entry.amount}
      <br />
      <code style={{fontSize: '.8rem' }}>Donated on {timeStamp}</code>
    </div>
  )
}

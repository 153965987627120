//needs to use firestore, not rt database actually

import { useState, useEffect } from 'react';
import Entry from './components/Entry';
import { v4 } from 'uuid';
import { firestoreDB } from './data/firebase';
import { collection, onSnapshot, query, orderBy, limit, setDoc, doc, updateDoc, getDocs, deleteDoc, getDoc} from "firebase/firestore";
import { serverTimestamp, increment } from "firebase/firestore";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import Modal from './components/Modal';
import leaderboardImg from './assets/leaderboard.png'
function App() {

  const [entries, setEntries] = useState([]);
  const [minimumDonation, setMinimumDonation] = useState(1);

  const [see100, setSee100] = useState(false);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(1);
  const [tos, setTOS] = useState(false);
  const [youveBeenAdded, setYouveBeenAdded] = useState(false);

  useEffect(() => {
    const q = query(collection(firestoreDB, "leaderboard"), orderBy("amount","desc"), orderBy("time","desc"), limit(parseInt(process.env.REACT_APP_MAX_ENTRIES)));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const records = [];
      querySnapshot.forEach((doc) => {
        records.push(doc.data());
      });
      setEntries(records);
      })
    
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function async() {
      const updateRef = doc(firestoreDB, "updates", "0");

      await updateDoc (updateRef, {
        updates: increment(1)
      });
      const newData = await getDoc(updateRef, "0");
      if (newData?.data()?.updates > parseInt(process.env.REACT_APP_MAX_ENTRIES)) {
        //clean database
        const records = [];
        const querySnapshot = await getDocs(collection(firestoreDB, "leaderboard"), orderBy("amount","desc"), orderBy("time","desc"),);
        querySnapshot.forEach((doc) => {
          records.push(doc.data());
        });
        if (records.length > parseInt(process.env.REACT_APP_MAX_ENTRIES)) {
          records.filter(r => r.amount < minimumDonation - 1).forEach(async (record) => {
            await deleteDoc(doc(firestoreDB, "leaderboard", record.id));
          })
        }
        await updateDoc(updateRef, {
          updates: 0
        });
      }
    }

    async()
    
  }, [entries, minimumDonation]);

  const addEntry = async (entry) => {
    await setDoc(doc(firestoreDB, "leaderboard", entry.id), entry);
    setYouveBeenAdded(true);
  }

  useEffect(() => {
    const setMinimum = async () => {
      //set minimum
      const records = [];
      const querySnapshot = await getDocs(collection(firestoreDB, "leaderboard"), orderBy("amount","desc"), orderBy("time","desc"),);
      querySnapshot.forEach((doc) => {
        records.push(doc.data());
      });
      if (records.length >= parseInt(process.env.REACT_APP_MAX_ENTRIES)) {
        setMinimumDonation(entries[entries?.length - 1].amount + 1);
        setAmount(entries[entries?.length - 1].amount + 1)
      }
    }

    if (entries.length > 0) {
      setMinimum();
    }
  }, [entries]);

/* modal */

 function close() {
    setName('');
    setAmount(minimumDonation || 1);
    setTOS(false);
    setOpen(false);
  }

  const handleSubmit = () => {
    if (!minimumDonation || minimumDonation <= amount) {
      const newEntry = { id: v4(), name, amount: parseInt(amount), time: serverTimestamp() };
      if (name?.length > 0 && amount > 0) addEntry(newEntry, entries);
      close();
    }
    else {
      window.alert(`Sorry. Your donation must be at least $${minimumDonation}. Please increase your donation to appear on the leaderboard.`);
    }
  }

  return (
  <PayPalScriptProvider options={{ "client-id":process.env.REACT_APP_PAYPAL_CLIENT_ID}}>
    <div className="App">
      {entries?.length > 0 ?
        (
          <div className='body'>
            <div className='imgContainer'>
              <img style={{width:'100%'}} src={ leaderboardImg } alt="the-leaderboard" />
            </div>
            <br />
              <div style={{ textAlign: 'center' }}>
          {!!youveBeenAdded &&<h2 style={{ color: '#6a5acd '}}> YOU'VE BEEN ADDED TO THE LEADERBOARD! </h2>}
          <h2 className="title">= Rules of The Leaderboard: =</h2>
          <ol>
            <li>The more money you donate, the higher your name will appear on The Leaderboard</li>
            <br/>
            <li>Only the top {process.env.REACT_APP_MAX_ENTRIES} entries will appear on The Leaderboard</li>
            <br/>
            <li>If your donation is not in the top {process.env.REACT_APP_MAX_ENTRIES} entries, you will be kicked off of The Leaderboard, never to return</li>
            <br/>
          </ol>
          {minimumDonation > 1 && <h2 style={{ color: 'blue' }}>Minimum donation: <span style={{ color: 'darkred' }}>{minimumDonation}</span></h2>}            
          
          <div className='bigButtonContainer'>
            <button className='bigButton' onClick={() => setOpen(true)}>
              Add me to The Leaderboard!
            </button>
          </div>
          </div>
          <h2>TOP 5 LEADERS</h2>
          <ol>
            {
              entries.slice(0,5).map((entry) => 
                <div key={entry.id}>
                  <Entry entry={entry} number={entries.indexOf(entry)} />
                  <br/>
                </div>
            )}
            </ol>
            {Boolean(entries?.length > 5) &&

            <>
            <h3>TOP 10 LEADERS</h3>
            <ul>
              {
                entries.slice(5, 10).map((entry) =>
                  <div key={entry.id}>
                    <Entry entry={entry} number={entries.indexOf(entry)} />
                    <br />
                  </div>
                )}
            </ul>
            </>
            }

            {Boolean(entries?.length > 10) &&
            <>
            <h4>TOP 20 LEADERS</h4>
            <ul>
              {
                entries.slice(10,20).map((entry) => 
                  <div key={entry.id}>
                    <Entry entry={entry} number={entries.indexOf(entry)}/>
                    <br/>
                  </div>
              )}
              </ul>
              </>}
          {Boolean(entries?.length > 99) && <button onClick={()=>setSee100(true)}>See More</button>}
            {Boolean(see100) &&          
            <>
            <h5>TOP 100 LEADERS</h5>
            <ul>
              {
                entries.slice(20,100).map((entry) => 
                  <div key={entry.id}>
                    <Entry entry={entry} number={entries.indexOf(entry)} />
                    <br/>
                  </div>
              )}
            </ul>
            </>
              }
              <small style={{color:'grey'}}>Ⓒ Z Rex Rodriguez {new Date().getFullYear()}</small>
          </div>)
        
        : <p>Loading leaderboard ...</p>}
      <Modal open={open} close={close} handleSubmit={handleSubmit}
        name={name} setName={setName}
        amount={amount} setAmount={setAmount}
        tos={tos} setTOS={setTOS}
        minimumDonation={minimumDonation}
      />
      </div>
      </PayPalScriptProvider>
  );
}

export default App;

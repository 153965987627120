import React from 'react'
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function PayPalButton({ amount, handleSubmit}) {


  const createOrder = (data, actions) => {
    console.log(handleSubmit)

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture();
  };

  return (
    <>
      <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => { onApprove(data, actions); handleSubmit(); }}
          fundingSource="paypal"  
      />
        <br/>
      
      <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => { onApprove(data, actions); handleSubmit(); }}
          fundingSource="paylater"  
        />
        <br/>

      <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => { onApprove(data, actions); handleSubmit(); }}
          fundingSource="applepay"  
        />
        <br/>

        
      <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
          fundingSource="venmo"
        />
        <br/>
          
      <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => { onApprove(data, actions); handleSubmit(); }}
          fundingSource="wechatpay"
        />
        
      </>
    )
}

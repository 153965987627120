import React from 'react'
import tosData from "../../data/tos.json";

export default function TOS({ tos, setTOS, next }) {
  
  const TOStext = tosData.find(i => i.version === process.env.REACT_APP_TOS_VERSION)?.text.replace('$n', process.env.REACT_APP_MAX_ENTRIES);

  return (
    <form onSubmit={next}>
      <div>
        <div style={{fontSize: '12px', maxHeight: '200px', overflowY: 'scroll'}}>{TOStext}</div>
        <br/>
        <br/>
      <label htmlFor="tos">I agree to the Terms of Service</label>
        <br/>
      
        <input required name="tos" type="checkBox" value={tos} onChange={(e) => { setTOS(e.target.value) }} />
      </div>
      <button type="submit" disabled={!tos}>Submit</button>
    </form>
  )
}
